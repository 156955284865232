import React from "react";
import { FormContainer, InfoContainer, LoginContainer } from "./CommonStyles";
import CRYPTOCOMM from "../img/cryptocomm.svg";
import ARSAT from "../img/logoarsat.svg";

const MainShared = ({ children }) => {
  return (
    <LoginContainer>
      <FormContainer>{children}</FormContainer>
      <InfoContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <img src={CRYPTOCOMM} width={170} height={28} alt="Logo Cryptocomm" />
          <img
            src={ARSAT}
            width={127}
            height={22}
            alt="Logo ARSAT"
            color="black"
          />
        </div>
      </InfoContainer>
    </LoginContainer>
  );
};

export default MainShared;
