import { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const setterUsername = (username) => {
    window.sessionStorage.setItem("username", username);
    setUsername(username);
  };
  useEffect(() => {
    let username = window.sessionStorage.getItem("username");
    if (username) {
      setUsername(username);
    }
  }, []);

  return (
    <UserContext.Provider value={{ username, setterUsername }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;
