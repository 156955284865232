import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import EAAS from "./img/eaas.svg";
import {
  ButtonBase,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  Menu,
  useMediaQuery,
} from "@mui/material";
import { ContactPage, Group } from "@mui/icons-material";
import { BLUE_COLOR } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";

const AppLayout = () => {
  const isMobile = useMediaQuery("(min-width:1100px)");
  const { username } = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          height: "calc(10% - 1px)",
          //   backgroundColor: "red",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid grey",
        }}
      >
        <img src={EAAS} alt="logoeaas" width={100} height={25} />
        <ButtonBase
          sx={{ marginLeft: "auto", marginRight: "20px" }}
          onClick={handleClick}
        >
          <div
            style={{
              width: 40,
              border: "1px solid ",
              borderRadius: "50%",
              height: 40,
              fontSize: 20,
              fontWeight: 700,
              color: "white",
              backgroundColor: BLUE_COLOR,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {`${username ? username[0].toUpperCase() : ""}${
              username ? username[1].toUpperCase() : ""
            }`}
          </div>
        </ButtonBase>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
        </Menu>
      </div>
      <div
        style={{
          width: "100%",
          height: "90%",
          //   backgroundColor: "green",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "12%",
            height: "100%",
            // backgroundColor: "violet",
            borderRight: "1px solid grey",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <MenuList sx={{ width: "90%", padding: "none" }}>
            <Tooltip title="Encuentre aquí las solicitudes de usuarios.">
              <MenuItem
                sx={{
                  paddingLeft: "0px !important",
                  display: "flex",
                }}
                onClick={() => navigate("/requests")}
              >
                <ContactPage
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#0073a5",
                  }}
                />
                {isMobile && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "3px",
                      marginLeft: "6px",
                    }}
                  >
                    Solicitudes
                  </Typography>
                )}
              </MenuItem>
            </Tooltip>
            <Tooltip title="Encuentre aquí los usuarios.">
              <MenuItem
                sx={{
                  paddingLeft: "0px !important",
                  display: "flex",
                }}
                onClick={() => navigate("/users")}
              >
                <Group
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#0073a5",
                  }}
                />
                {isMobile && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "3px",
                      marginLeft: "6px",
                    }}
                  >
                    Usuarios
                  </Typography>
                )}
              </MenuItem>
            </Tooltip>
          </MenuList>
        </div>
        <div
          style={{
            width: "88%",
            height: "100%",
            // backgroundColor: "orange",
            // display: "flex",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
