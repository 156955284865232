import { LoadingButton } from "@mui/lab";
import { Typography, styled } from "@mui/material";

export const NewAccountButton = styled(LoadingButton)({
  width: "140px",
  height: "36px",
  backgroundColor: "#0073a5",
  marginTop: "12px",
  "&:hover": {
    backgroundColor: "#0073a5",
  },
  color: "white",
  borderRadius: 10,
  paddingBottom: 5,
  cursor: "pointer",
  textTransform: "none",
});

export const LoginContainer = styled("div")({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // backgroundColor: "#535554",
});

export const FormContainer = styled("div")({
  width: "100%",
  height: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const InfoContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "90%",
  height: "10%",
});

export const ErrorMessage = styled(Typography)({
  color: "red",
  marginLeft: "4px",
  marginTop: "4px",
  alignSelf: "flex-start",
});
