import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "../shared-components/ProtectedRoute";
import AppLayout from "./appLayout/AppLayout";
import Users from "./users/Users";
import Login from "./login/Login";
import useAuth from "../hooks/useAuth";
import Requests from "./requests/Requests";
import SessionExpired from "./sessionExpired/SessionExpired";

const Main = () => {
  const { isAuth } = useAuth();
  return (
    // <BrowserRouter>
    <Routes>
      <Route
        // path="/"
        element={isAuth ? <AppLayout /> : <Navigate to="/login" replace />}
      >
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/requests"
          element={
            <ProtectedRoute>
              <Requests />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/login"
        element={isAuth ? <Navigate to="/requests" replace /> : <Login />}
      />
      <Route path="/session_expired" element={<SessionExpired />} />
      <Route
        path="*"
        element={
          isAuth ? (
            <Navigate to="/requests" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
    </Routes>
    // </BrowserRouter>
  );
};

export default Main;
