import { LoadingButton } from "@mui/lab";
import { Card, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BLUE_COLOR } from "../../utils/constants";
import { ArrowBack } from "@mui/icons-material";
import useAxios from "../../hooks/useAxios";
import Loading from "../../shared-components/Loading";

const RecoverPassword = ({ userData, handleBack, refetch }) => {
  const { acceptRecoverPassword, getUserKeys } = useAxios();
  const [userInfoState, setUserInfoState] = useState({
    state: "INITIAL",
    data: null,
  });
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const recoverPasswordAccept = () => {
    setIsLoadingButton(true);
    let recoverPasswordPayload = {
      id: userData.id,
    };
    acceptRecoverPassword(recoverPasswordPayload)
      .then(async (data) => {
        await refetch();
        setUserInfoState({ state: "KEYS", data });
        setIsLoadingButton(false);
      })
      .catch((err) => {
        setIsLoadingButton(false);
        // TODO VER COMO DISPLAY ERROR
        console.log({ recoverPwErr: err });
      });
  };

  const getRender = () => {
    switch (userInfoState.state) {
      case "INITIAL":
        return (
          <Card
            sx={{
              minHeight: "200px",
              minWidth: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Typography sx={{ marginX: "20px", fontWeight: "600" }}>
              Usted va a aceptar el pedido de
              <br />
              cambio de contraseña del usuario {userData.name}
              <br />
              Esta seguro que desea confirmar?
            </Typography>
            <LoadingButton
              sx={{
                width: "320px",
                textTransform: "none",
                marginTop: "20px",
                backgroundColor: BLUE_COLOR,
                "&:hover": {
                  backgroundColor: BLUE_COLOR,
                },
              }}
              variant="contained"
              onClick={recoverPasswordAccept}
              loading={isLoadingButton}
            >
              Confirmar
            </LoadingButton>
          </Card>
        );
      case "KEYS":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
              }}
            >
              Envíe los tres códigos por medios diferentes
            </Typography>
            <div>
              <Typography
                sx={{ fontSize: "18px", marginBottom: "6px", marginTop: "6px" }}
              >
                {`Vía email (${userData?.email}):`}
              </Typography>
              <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
                Usted ha solicitado el blanqueo de su contraseña en la
                aplicación Cryptocomm EaaS.
                <br />
                {`Abra la aplicación usando el siguiente link: `}
                <a
                  href={`cryptocomm://eaas.arsat.com.ar/recover?key=${userInfoState.data?.first_password}`}
                >
                  {`cryptocomm://eaas.arsat.com.ar/recover?key=${userInfoState.data?.first_password}`}
                </a>
                <br />
                Código 1 de 3: {userInfoState.data?.first_password}
              </div>
            </div>
            <div>
              <Typography
                sx={{ fontSize: "18px", marginBottom: "6px", marginTop: "6px" }}
              >
                {`Vía Whatsapp (${userData?.mobile_phone}):`}
              </Typography>
              <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
                Usted ha solicitado el blanqueo de su contraseña en la
                aplicación Cryptocomm EaaS.
                <br />
                Código 2 de 3: {userInfoState.data?.second_password}
              </div>
            </div>
            <div>
              <Typography
                sx={{ fontSize: "18px", marginBottom: "6px", marginTop: "6px" }}
              >
                {`Vía SMS o llamada telefónica (${userData?.phone}):`}
              </Typography>
              <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
                Usted ha solicitado el blanqueo de su contraseña en la
                aplicación Cryptocomm EaaS.
                <br />
                Código 3 de 3: {userInfoState.data?.third_password}
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  useEffect(() => {
    if (userData?.finishRecover) {
      getUserKeys({ id: userData.id })
        .then((res) => {
          setUserInfoState({ state: "KEYS", data: res });
          setIsLoadingKeys(false);
        })
        .catch((err) => setIsLoadingKeys(false));
    } else {
      setIsLoadingKeys(false);
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoadingKeys ? (
        <Loading />
      ) : (
        <>
          {!isLoadingButton && (
            <div style={{ height: "12%", alignSelf: "flex-start" }}>
              <IconButton onClick={handleBack} sx={{ padding: "16px" }}>
                <ArrowBack sx={{ color: "black" }} />
              </IconButton>
            </div>
          )}
          <div
            style={{
              height: isLoadingButton ? "100%" : "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // paddingBottom: "4%",
            }}
          >
            {getRender()}
          </div>
        </>
      )}
    </div>
  );
};

export default RecoverPassword;
