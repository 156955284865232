import "./App.css";
import AxiosProvider from "./contexts/AxiosContext";
import AuthProvider from "./contexts/AuthContext";
import Main from "./views/Main";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./contexts/UserContext";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AxiosProvider>
          <AuthProvider>
            <UserProvider>
              <Main />
            </UserProvider>
          </AuthProvider>
        </AxiosProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
export default App;
