import React, { useEffect, useState } from "react";
import { Avatar, Typography, Button, Card, IconButton } from "@mui/material";
import { BLUE_COLOR } from "../../utils/constants";
import useAxios from "../../hooks/useAxios";
import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useQuery } from "react-query";
import Loading from "../../shared-components/Loading";
import { getDate } from "../../utils/utils";

const UserInfo = ({ userData, refetch, handleBack }) => {
  const {
    acceptUser: acceptUserEndpoint,
    blockUser: blockUserEndpoint,
    getOrg,
    getUserKeys,
  } = useAxios();
  const [userInfoState, setUserInfoState] = useState({
    state: "INITIAL",
    data: null,
  });
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const getAvatarName = (name) => {
    return `${name[0].toUpperCase()}${name[1].toUpperCase()}`;
  };

  const { data, isLoading } = useQuery(["org"], getOrg);

  const blockUser = () => {
    setIsLoadingButton(true);
    let blockPayload = {
      id: userData.id,
    };
    blockUserEndpoint(blockPayload)
      .then(async (res) => {
        await refetch();
        setIsLoadingButton(false);
        handleBack();
      })
      .catch((err) => {
        setIsLoadingButton(false);
        console.log({ blockErr: err });
      });
  };

  const acceptUser = () => {
    setIsLoadingButton(true);
    let acceptPayload = {
      id: userData.id,
      expire_date: "2024/01/01",
    };
    acceptUserEndpoint(acceptPayload)
      .then(async (data) => {
        await refetch();
        setUserInfoState({ state: "KEYS", data });
        setIsLoadingButton(false);
      })
      .catch((err) => {
        setIsLoadingButton(false);
        // TODO VER COMO DISPLAY ERROR
        console.log({ acceptErr: err });
      });
  };

  const back = () => {
    switch (userInfoState.state) {
      case "INITIAL":
        return handleBack();
      case "CONFIRM":
      case "BLOCK":
        return setUserInfoState({ state: "INITIAL", data: null });
      case "ALL_KEYS":
      case "KEYS":
        return handleBack();
      default:
        return handleBack();
    }
  };

  const getRender = () => {
    switch (userInfoState.state) {
      case "INITIAL":
        return (
          <>
            <Avatar
              sx={{
                width: "60px",
                height: "60px",
                marginBottom: "6px",
                backgroundColor: BLUE_COLOR,
              }}
            >
              {getAvatarName(userData?.name)}
            </Avatar>
            <Typography
              sx={{
                color: "black",
                fontWeight: "600",
                marginY: "10px",
                fontSize: "16px",
              }}
            >
              {`Nombre: ${userData.name}`}
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontWeight: "600",
                marginY: "10px",
                fontSize: "16px",
              }}
            >
              {`Email: ${userData.email}`}
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontWeight: "600",
                marginY: "10px",
                fontSize: "16px",
              }}
            >
              {`Organización: ${userData.organization}`}
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontWeight: "600",
                marginY: "10px",
                fontSize: "16px",
              }}
            >
              {`Telefono principal: ${userData.phone}`}
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontWeight: "600",
                marginY: "10px",
                fontSize: "16px",
              }}
            >
              {`Telefono secundario: ${userData.mobile_phone}`}
            </Typography>
            <Button
              variant="contained"
              sx={{
                marginY: "8px",
                textTransform: "none",
                backgroundColor: BLUE_COLOR,
                "&:hover": {
                  backgroundColor: BLUE_COLOR,
                },
              }}
              onClick={() => {
                setUserInfoState({ state: "CONFIRM", data: null });
              }}
            >
              Aceptar usuario
            </Button>
            <Button
              variant="contained"
              sx={{
                marginY: "8px",
                textTransform: "none",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              onClick={() => {
                setUserInfoState({ state: "BLOCK", data: null });
              }}
            >
              Bloquear usuario
            </Button>
          </>
        );
      case "CONFIRM":
        return (
          <Card
            sx={{
              minHeight: "200px",
              minWidth: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Typography sx={{ marginX: "20px", fontWeight: "600" }}>
              Usted va a aceptar al usuario {userData.name}
              <br />
              hasta el dia {getDate(data.expire_date)}
              <br />
              Esta seguro que desea confirmar?
            </Typography>
            <LoadingButton
              sx={{
                width: "320px",
                textTransform: "none",
                marginTop: "20px",
                backgroundColor: BLUE_COLOR,
                "&:hover": {
                  backgroundColor: BLUE_COLOR,
                },
              }}
              variant="contained"
              onClick={acceptUser}
              loading={isLoadingButton}
            >
              Confirmar
            </LoadingButton>
          </Card>
        );
      case "BLOCK":
        return (
          <Card
            sx={{
              minHeight: "200px",
              minWidth: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Typography sx={{ marginX: "20px", fontWeight: "600" }}>
              Usted va a bloquear al usuario {userData.name}
              <br />
              Esta seguro que desea bloquearlo?
            </Typography>
            <LoadingButton
              sx={{
                width: "320px",
                textTransform: "none",
                marginTop: "20px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              variant="contained"
              onClick={blockUser}
              loading={isLoadingButton}
            >
              Bloquear
            </LoadingButton>
          </Card>
        );
      case "ALL_KEYS":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
              }}
            >
              Envíe los tres códigos por diferentes medios
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
              }}
            >
              {`Vía correo (${userData?.email}):`}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              *Recuerde que el primer código ya fue enviado por correo al
              usuario.
            </Typography>
            <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
              Usted ha solicitado la activación del Cryptocomm EaaS
              <br />
              Código 1 de 3: {userInfoState.data?.first_password}
            </div>
            <div>
              <Typography
                sx={{ fontSize: "18px", marginBottom: "6px", marginTop: "6px" }}
              >
                {`Vía Whatsapp (${userData?.mobile_phone}):`}
              </Typography>
              <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
                Usted ha solicitado la activación del Cryptocomm EaaS
                <br />
                Código 2 de 3: {userInfoState.data?.second_password}
              </div>
            </div>
            <div>
              <Typography
                sx={{ fontSize: "18px", marginBottom: "6px", marginTop: "6px" }}
              >
                {`Vía SMS o llamada telefónica (${userData?.phone}):`}
              </Typography>
              <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
                Usted ha solicitado la activación del Cryptocomm EaaS
                <br />
                Código 3 de 3: {userInfoState.data?.third_password}
              </div>
            </div>
          </div>
        );
      case "KEYS":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
              }}
            >
              El primer código fue enviado al usuario por correo.
              <br />
              Envíe los dos códigos restantes por difrentes medios:
            </Typography>
            <div>
              <Typography
                sx={{ fontSize: "18px", marginBottom: "6px", marginTop: "6px" }}
              >
                {`Vía Whatsapp (${userData?.mobile_phone}):`}
              </Typography>
              <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
                Usted ha solicitado la activación del Cryptocomm EaaS
                <br />
                Código 2 de 3: {userInfoState.data?.second_password}
              </div>
            </div>
            <div>
              <Typography
                sx={{ fontSize: "18px", marginBottom: "6px", marginTop: "6px" }}
              >
                {`Vía SMS o llamada telefónica (${userData?.phone}):`}
              </Typography>
              <div style={{ padding: "16px", backgroundColor: "#E1E1E1" }}>
                Usted ha solicitado la activación del Cryptocomm EaaS
                <br />
                Código 3 de 3: {userInfoState.data?.third_password}
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (userData?.waitingVerify) {
      getUserKeys({ id: userData.id })
        .then((res) => {
          setUserInfoState({ state: "ALL_KEYS", data: res });
          setIsLoadingKeys(false);
        })
        .catch((err) => setIsLoadingKeys(false));
    } else {
      setIsLoadingKeys(false);
    }
  }, []);

  return (
    <>
      {isLoading || isLoadingKeys ? (
        <Loading />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {!isLoadingButton && (
            <div style={{ height: "12%", alignSelf: "flex-start" }}>
              <IconButton onClick={back} sx={{ padding: "16px" }}>
                <ArrowBack sx={{ color: "black" }} />
              </IconButton>
            </div>
          )}
          <div
            style={{
              height: isLoadingButton ? "100%" : "84%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // paddingBottom: "4%",
            }}
          >
            {getRender()}
          </div>
        </div>
      )}
    </>
  );
};

export default UserInfo;
