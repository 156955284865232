import { Password, RemoveRedEye, Search } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { getDate } from "../../utils/utils";

export const formatData = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  let dateFormatedData = data?.map((user) => {
    return { ...user, created: getDate(user.created) };
  });
  let translatedData = dateFormatedData?.map((user) => {
    if (user.state === "Validated") {
      return { ...user, state: "Validado" };
    }
    if (user.state === "WaitingValidation") {
      return { ...user, state: "Activación cuenta" };
    }
    if (user.state === "WaitingVerify") {
      return { ...user, state: "Activación cuenta", waitingVerify: true };
    }
    if (user.state === "BlockedByAdmin") {
      return { ...user, state: "Bloqueado" };
    }
    if (user.state === "RecoverPassword") {
      return { ...user, state: "Recuperando contraseña" };
    }
    if (user.state === "FinishRecoverPassword") {
      return { ...user, state: "Recuperando contraseña", finishRecover: true };
    }
    return user;
  });
  let buckets = [[], [], [], []];
  translatedData.forEach((user) => {
    switch (user.state) {
      case "Activación cuenta":
        return buckets[0].push(user);
      case "Recuperando contraseña":
        return buckets[1].push(user);
      case "Bloqueado":
        return buckets[2].push(user);
      case "Validado":
        return buckets[3].push(user);
      default:
        return;
    }
  });
  let result = [];
  buckets.forEach((bucket) => {
    result = result.concat(bucket);
  });
  return result;
};

const renderDetailsButton = (params, setter) => {
  return (
    <Tooltip title="Ver pedido de activación">
      <IconButton
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        onClick={() => {
          setter({ state: "USER_INFO", data: params?.row });
        }}
      >
        <Search />
      </IconButton>
    </Tooltip>
  );
};

const renderViewButton = (params, setter) => {
  return (
    <Tooltip title="Ver códigos de activación">
      <IconButton
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        onClick={() => {
          setter({ state: "USER_INFO", data: params?.row });
        }}
      >
        <RemoveRedEye />
      </IconButton>
    </Tooltip>
  );
};

const renderRecoverButton = (params, setter) => {
  return (
    <Tooltip title="Ver pedido de recuperación">
      <IconButton
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        onClick={() => {
          setter({ state: "RECOVER_PASSWORD", data: params?.row });
        }}
      >
        <Password />
      </IconButton>
    </Tooltip>
  );
};

const renderRecoverViewButton = (params, setter) => {
  return (
    <Tooltip title="Ver códigos de recuperación">
      <IconButton
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        onClick={() => {
          setter({ state: "RECOVER_PASSWORD", data: params?.row });
        }}
      >
        <RemoveRedEye />
      </IconButton>
    </Tooltip>
  );
};

export const getColumns = (setter) => {
  return [
    {
      field: "created",
      headerName: "Fecha de solicitud",
      flex: 0.7,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Nombre",
      // width: 130,
      flex: 0.7,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      // width: 220,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "organization",
      headerName: "Organizacion",
      // width: 150,
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "state",
      headerName: "Estado",
      // width: 130,
      flex: 0.8,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "info",
      headerName: "  ",
      width: 70,
      renderCell: (params) => {
        let state = params?.row?.state;
        switch (state) {
          case "Activación cuenta":
            if (params?.row?.waitingVerify) {
              return renderViewButton(params, setter);
            } else {
              return renderDetailsButton(params, setter);
            }
          case "Recuperando contraseña":
            if (params?.row?.finishRecover) {
              return renderRecoverViewButton(params, setter);
            } else {
              return renderRecoverButton(params, setter);
            }
          default:
            return <></>;
        }
      },
      disableColumnMenu: true,
      sortable: false,
    },
  ];
};
