import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import useAuth from "../hooks/useAuth";

const ProtectedRoute = ({ children }) => {
  const { logout } = useAuth();

  let user = {
    scope: ["admin"],
  };
  //TODO
  if (!user?.scope?.includes("admin")) {
    logout();
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
