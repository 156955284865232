import React from "react";
import { CircularProgress } from "@mui/material";
import { BLUE_COLOR } from "../utils/constants";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress sx={{ color: BLUE_COLOR }} />
    </div>
  );
};

export default Loading;
