import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainShared from "../../shared-components/MainShared";
import { NewAccountButton } from "../../shared-components/CommonStyles";

const SessionExpired = () => {
  const navigate = useNavigate();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  return (
    <MainShared>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Typography
          sx={{ fontWeight: "700", fontSize: "24px", paddingTop: 20 }}
        >
          La sesión ha expirado.
        </Typography>
        <div style={{ paddingTop: 60, width: 250, paddingBottom: 50 }}>
          Su sesión se ha finalizado por razones de seguridad. Por favor vuelva
          a ingresar para retomar la actividad.
        </div>
        <NewAccountButton
          onClick={() => {
            setIsLoadingButton(true);
            setTimeout(() => {
              navigate("/login");
              setIsLoadingButton(false);
            }, 3000);
          }}
          variant="contained"
          loading={isLoadingButton}
        >
          <span style={{ fontSize: "12px" }}>Volver a ingresar</span>
        </NewAccountButton>
      </div>
    </MainShared>
  );
};

export default SessionExpired;
